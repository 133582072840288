import request from "@/request";

export const getMenuList = (params) =>
  request.get("/api/system/menus", { params });
export const getMenuDetail = (id) => request.get(`/api/system/menus/${id}`);
export const getMenuArray = (params) =>
  request.get("/api/system/menus/menuarray/", { params });

export const addMenu = (data) => request.post("/api/system/menus/", data);
export const updateMenu = (data) =>
  request.patch(`/api/system/menus/${data.id}/`, data);
