<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">菜单详情</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-alert
      v-model="alert"
      :type="alert_type"
      dense
      dismissible
      height="30"
      style="position:fixed;z-index:9;width:70%;margin-left:100px;margin-top:100px;padding-top:10px;padding-bottom:40px;"
    >
      {{ alert_content }}
    </v-alert>

    <div class="form-item">
      <div>Id</div>
      <v-text-field solo dense disabled readonly :value="item.id">
      </v-text-field>
    </div>
    <div class="form-item">
      <div>名称</div>
      <v-text-field label="name" solo dense v-model="item.name"> </v-text-field>
    </div>
    <div class="form-item">
      <div>标题</div>
      <v-text-field label="Title" solo dense v-model="item.title">
      </v-text-field>
    </div>
    <div class="form-item">
      <div>路径</div>
      <v-text-field label="Path" solo dense v-model="item.path"> </v-text-field>
    </div>
    <div class="form-item">
      <div>父级菜单</div>
      <v-select
        v-model="item.parent"
        :items="first_level_menu"
        dense
        solo
        item-value="id"
        item-text="title"
      ></v-select>
    </div>
    <div class="form-item">
      <div>有权访问该菜单的用户组（不选表示所有用户组均可访问）</div>
      <v-row dense>
        <v-col
          :cols="6"
          sm="2"
          v-for="(group, index) in usergroups"
          :key="index"
        >
          <v-checkbox
            multiple
            v-model="item.groups"
            class="mx-2"
            :label="group"
            :value="group"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <div class="form-item">
      <!-- <span>状态</span> -->
      <!-- <v-select v-model="item.base_status" :items="status_list" dense solo></v-select> -->
      <v-switch v-model="item.base_status">
        <template v-slot:label>
          <span :class="item.base_status ? 'primary--text' : ''">
            {{ item.base_status ? "启用菜单" : "禁用菜单" }}
          </span>
        </template>
      </v-switch>
    </div>

    <v-btn color="primary" @click="saveItem()">确定修改</v-btn>
  </v-container>
</template>
<script>
import { getMenuList, getMenuDetail, addMenu, updateMenu } from "@/api/menus";
import { getGroupList } from "@/api/usergroups";
// import { mapState } from "vuex";
export default {
  name: "userHome",
  props: {
    id: [String, Number],
  },
  data: () => ({
    item: {},
    first_level_menu: [],
    usergroups: [],
    status_list: [
      { value: true, text: "启用" },
      { value: false, text: "禁用" },
    ],
    alert: false,
    alert_type: "warning",
    alert_content: "",
  }),

  methods: {
    saveItem() {
      let _this = this;
      let data = { ...this.item };
      if (!this.id) {
        addMenu(data)
          .then(() => {
            this.alert_type = "success";
            this.alert_content = "添加菜单成功";
            this.alert = true;
            setTimeout(() => {
              _this.alert = false;
            }, 3000);
            _this.$router.push({ name: "menus" });
          })
          .catch((error) => {
            this.alert_type = "warning";
            this.alert_content = "添加菜单失败。" + error;
            this.alert = true;
            setTimeout(() => {
              _this.alert = false;
            }, 5000);
          });
      } else {
        updateMenu(data)
          .then(() => {
            this.alert_type = "success";
            this.alert_content = "更新菜单成功";
            this.alert = true;
            setTimeout(() => {
              _this.alert = false;
            }, 3000);
          })
          .catch((error) => {
            this.alert_type = "warning";
            this.alert_content = "更新菜单失败。" + error;
            this.alert = true;
            setTimeout(() => {
              _this.alert = false;
            }, 5000);
          });
      }
    },
  },
  async mounted() {
    console.log("this.id = ", this.id);
    if (this.id) {
      await getMenuDetail(this.id).then((result) => {
        this.item = result;
      });
    }
    await getMenuList({ no_parent: true }).then((result) => {
      this.first_level_menu = result.results;
    });
    await getGroupList().then((result) => {
      if (result.results && result.results.length > 0) {
        result.results.forEach((item) => {
          this.usergroups.push(item.name);
        });
      }
    });
  },
};
</script>
<style lang="stylus">
.n-title
	font-size 32px
.u-container
	display flex
	.u-container-item
		flex 1
		.form-item
			margin-top: -10px
</style>
