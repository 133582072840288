import request from "@/request";
/*
 * @Description: 获取用户组列表
 * @Author: 张宇童
 * @Date: 2020/9/13 15:22
 */
export const getGroupList = (params) =>
  request.get("/api/system/groups", { params });
export const getGroupDetail = (id) => request.get(`/api/system/groups/${id}`);
/*
 * @Description: 添加用户组
 * @Author: 张宇童
 * @Date: 2020/9/14 11:24
 */
export const addGroup = (data) => request.post("/api/system/groups/", data);
export const updateGroup = (data) =>
  request.patch(`/api/system/groups/${data.id}/`, data);
